













































import {Component, Prop, Vue} from "vue-property-decorator";
import kisiselBilgiSorgulama, {
    AvukatKisiselBilgileriSorgulama,
    AvukatKisiselBilgileriSorgulamaCevap
} from "@/classes/UyapClient/AvukatKisiselBilgileriSorgulama";
import bocluTakbisSorgulama from "@/classes/UyapClient/BorcluTakbisSorgulama";

@Component({})
export default class DenemeTahtasi extends Vue {

    kisiselBilgileriSorgulamaCevap: AvukatKisiselBilgileriSorgulamaCevap | null = null;
    kisiselBilgileriSorgulamaHata: any = "";

    borcluTakbisSorgulamaCevap: any = "";
    borcluTakbisSorgulamaHata: any = "";

    kisiselBilgiler() {
        kisiselBilgiSorgulama.run({})
            .then((cvp) => this.kisiselBilgileriSorgulamaCevap = cvp)
            .catch((err) => this.kisiselBilgileriSorgulamaHata = err);
    }

    borcluTakbis() {
        bocluTakbisSorgulama.run({
            dosyaId: "",
            kisiKurumId: ""
        }).then((cvp) => {
            this.borcluTakbisSorgulamaCevap = cvp;
        }).catch((err) => {
            this.borcluTakbisSorgulamaHata = err;
        });
    }

}
