import {UyapClient} from "@/classes/UyapClient/UyapClient";
import {Method} from "axios";

export interface AvukatKisiselBilgileriSorgulamaTalep {

}

export interface AvukatKisiselBilgileriSorgulamaCevap {
    ad: string,
    adresList: string,
    anaAd: string,
    avukatId: string,
    babaAd: string,
    bagliOlduguBaroAdi: string,
    baroNo: string,
    dogumTarihi: string,
    dogumYeri: string,
    isMock: string,
    isTCGecerli: string,
    kamuAvukatiMi: string,
    kisiKurumId: string,
    lisans: string,
    mernisGuncellemesiYapildi: string,
    soyad: string,
    tbbNo: string,
    tcKimlikNo: string,
    vekilTipiKod: string,
    vergiNo: string,
    bakiye: string,
}


export class AvukatKisiselBilgileriSorgulama extends UyapClient<AvukatKisiselBilgileriSorgulamaTalep, AvukatKisiselBilgileriSorgulamaCevap> {
    getMethod(): Method {
        return "get";
    }

    getMockResponse(): string {
        return `<root><object-array>
  <AvukatTumBilgilerDVO>
    <isMock>false</isMock>
    <avukatId>1746688</avukatId>
    <kisiKurumId>94071819</kisiKurumId>
    <tcKimlikNo>14537825364</tcKimlikNo><lisans>TVRJNU16ZzJOVE0zTnpaVFUxTXhOVGs1TVRjeE5UZzRAQEBMVEUxT1RreE5qZzNPREE9</lisans>
    <ad>NİHAN</ad>
    <soyad>ERDOĞAN</soyad>
    <babaAd>MUSTAFA</babaAd>
    <anaAd>AYŞE NEVİN</anaAd>
    <dogumTarihi>11/08/1976</dogumTarihi>
    <dogumYeri>ANKARA</dogumYeri>
    <mernisGuncellemesiYapildi>false</mernisGuncellemesiYapildi>
    <isTCGecerli>true</isTCGecerli>
    <vekilTipiKod>BARO</vekilTipiKod>
    <baroNo>20112</baroNo>
    <tbbNo>78119</tbbNo>
    <bagliOlduguBaroAdi>ANKARA BAROSU</bagliOlduguBaroAdi>
    <vergiNo>14537825364</vergiNo>
    <adresList/>
    <kamuAvukatiMi>false</kamuAvukatiMi>
  </AvukatTumBilgilerDVO>
  <ResultEvent>
    <type>success</type>
    <value>10.39</value>
    <log/>
  </ResultEvent>
  <AvukatBakiyeDVO>
    <hasBarokart>false</hasBarokart>
    <sorguToplamIslemSayisi>0</sorguToplamIslemSayisi>
    <sorguKalanIslemSayisi>0</sorguKalanIslemSayisi>
    <ucretsizSorguLimit>0</ucretsizSorguLimit>
  </AvukatBakiyeDVO>
  <AvukatBakiyeDVO>
    <hasBarokart>false</hasBarokart>
    <sorguToplamIslemSayisi>0</sorguToplamIslemSayisi>
    <sorguKalanIslemSayisi>0</sorguKalanIslemSayisi>
    <ucretsizSorguLimit>0</ucretsizSorguLimit>
  </AvukatBakiyeDVO>
</object-array></root>
`;
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'main/jsp/avukat/avukat_kisisel_bilgileri_sorgula.ajx';
    }

    parse(data: string): Promise<AvukatKisiselBilgileriSorgulamaCevap> {
        return new Promise<AvukatKisiselBilgileriSorgulamaCevap>((resolve, reject) => {
            try {
                let json = this.xml2json(data).root['object-array'];
                let obj = json.AvukatTumBilgilerDVO;
                obj.bakiye = json.ResultEvent.value;

                return resolve(obj)
            } catch (e) {
                reject(e.message);
            }

        });
    }

}

export default new AvukatKisiselBilgileriSorgulama();
